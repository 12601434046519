var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container px-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center w-100"
  }, [_c('div', {
    staticClass: "heater-icon my-3"
  }, [_c('StatBtn', {
    staticClass: "badge temp-t0",
    class: _vm.furnanceBadge,
    attrs: {
      "variable": "t0",
      "driver": "piec"
    }
  }, [_vm._v(" " + _vm._s(_vm.piec.t0) + " "), _c('i', {
    staticClass: "fas fa-chart-bar ml-2"
  })]), _c('StatBtn', {
    staticClass: "badge badge-light temp-t1",
    attrs: {
      "variable": "t1",
      "driver": "piec"
    }
  }, [_vm._v(" " + _vm._s(_vm.piec.t1) + " "), _c('i', {
    staticClass: "fas fa-chart-bar ml-2"
  })]), _c('heater-svg', {
    attrs: {
      "width": "100%"
    }
  })], 1)]), _c('div', [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('FunctionBtn', {
    staticClass: "my-3",
    attrs: {
      "show-name": "",
      "driver": "piec",
      "func": "pomppiec",
      "autoload": true,
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('StatBtn', {
          staticClass: "btn btn-outline-primary",
          attrs: {
            "variable": "ppie",
            "driver": "piec",
            "type": "count",
            "data-param": "count"
          }
        }, [_c('i', {
          staticClass: "fas fa-chart-bar"
        })])];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('FunctionBtn', {
    staticClass: "my-1",
    attrs: {
      "show-name": "",
      "driver": "piec",
      "func": "pompgrzejniki",
      "autoload": true,
      "size": "md"
    }
  })], 1)]), _c('h5', [_vm._v("Statystyki")]), _c('div', {
    staticClass: "row"
  }), _c('div', {
    staticClass: "col-12 col-sm-6"
  }, [_c('StatBtn', {
    staticClass: "btn btn-success btn-block m-2",
    attrs: {
      "variable": "ppie",
      "driver": "piec",
      "type": "line",
      "data-param": "count"
    }
  }, [_vm._v(" Czas pompy pieca "), _c('i', {
    staticClass: "fas fa-chart-bar ml-2"
  })])], 1), _c('div', {
    staticClass: "col-12 col-sm-6"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }