<template>
  <div class="container px-0">
    <div class="d-flex justify-content-center w-100">
      <div class="heater-icon my-3">
        <StatBtn
          class="badge temp-t0"
          :class="furnanceBadge"
          variable="t0"
          driver="piec"
        >
          {{ piec.t0 }}
          <i class="fas fa-chart-bar ml-2"></i>
        </StatBtn>

        <StatBtn class="badge badge-light temp-t1" variable="t1" driver="piec">
          {{ piec.t1 }}
          <i class="fas fa-chart-bar ml-2"></i>
        </StatBtn>

        <heater-svg width="100%" />
      </div>
    </div>

    <div>
      <div class="d-flex justify-content-center">
        <FunctionBtn
          show-name
          driver="piec"
          func="pomppiec"
          :autoload="true"
          size="md"
          class="my-3"
        >
          <template #append>
            <StatBtn
              class="btn btn-outline-primary"
              variable="ppie"
              driver="piec"
              type="count"
              data-param="count"
            >
              <i class="fas fa-chart-bar"></i>
            </StatBtn>
          </template>
        </FunctionBtn>
      </div>

      <div class="d-flex justify-content-center">
        <FunctionBtn
          show-name
          driver="piec"
          func="pompgrzejniki"
          :autoload="true"
          size="md"
          class="my-1"
        />
      </div>
    </div>

    <h5>Statystyki</h5>
    <div class="row"></div>
    <div class="col-12 col-sm-6">
      <StatBtn
        class="btn btn-success btn-block m-2"
        variable="ppie"
        driver="piec"
        type="line"
        data-param="count"
      >
        Czas pompy pieca
        <i class="fas fa-chart-bar ml-2"></i>
      </StatBtn>
    </div>
    <!-- <div class="col-12 col-sm-6">
        <StatBtn
          class="btn btn-success btn-block m-2"
          variable="pgrz"
          driver="piec"
          type="line"
        >
          Czas pompy grzejników
          <i class="fas fa-chart-bar ml-2"></i>
        </StatBtn>
      </div> -->
    <div class="col-12 col-sm-6"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'

import StatBtn from '../components/helpers/stat-btn.vue'

export default {
  name: 'FurnaceView',
  components: {
    StatBtn,
    HeaterSvg: () => import('@/components/svg/heater')
  },
  data() {
    return {
      mBus: window.mBus
    }
  },
  computed: {
    ...mapState(['driversData']),

    piec() {
      if ('piec' in this.driversData) {
        return this.driversData.piec
      } else {
        return false
      }
    },

    // select class: badge-danger - on, badge-light off
    furnanceBadge() {
      if (this.piec.ppie) {
        return 'badge-danger'
      } else {
        return 'badge-light'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.heater-icon {
  position: relative;
  max-width: 260px;

  .temp-t0,
  .temp-t1 {
    min-width: 30%;
  }

  .temp-t0 {
    position: absolute;
    right: 10px;
    top: 2%;
    font-size: 1.2rem;
  }

  .temp-t1 {
    position: absolute;
    right: 10px;
    top: 55%;
    font-size: 1.2rem;
  }
}
</style>
